// https://nextjs.org/docs/advanced-features/custom-error-page#404-page

import Link from 'next/link'
import Head from 'next/head'

import { NextSeo } from 'next-seo'

import Layout from 'web/components/Layout'

function PageNotFound() {
  return (
    <Layout notFound>
      <NextSeo title="404 Page Not Found" />

      <section className="mt-10 space-y-5 text-center">
        <h1>Page could not be summoned!</h1>

        <p>
          404! Page not found. <Link href="/">Go back home</Link>.
        </p>
      </section>
    </Layout>
  )
}

export default PageNotFound
